import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import CartProvider from './context/CartContext';
import SearchProvider from './context/SearchContext';
import { PayPalScriptProvider } from "@paypal/react-paypal-js"
import { initMercadoPago } from '@mercadopago/sdk-react'
import FilterProvider from './context/FilterContext';
import 'bootstrap/dist/css/bootstrap.min.css';
import { ThemeProvider } from '@mui/material/styles';
import TextStyleGlobal from './global/TextGlobal';
import { CookieProvider } from './context/CookieContext';

initMercadoPago(process.env.REACT_APP_MERCADOPAGO_EF_PUBLIC_KEY, {
    locale: 'es-AR'
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <CookieProvider>
        <PayPalScriptProvider options={{ "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID }} >
            {/* <FilterProvider> */}
            <SearchProvider>
                <CartProvider>
                    <ThemeProvider theme={TextStyleGlobal}>
                        <App />
                    </ThemeProvider>
                </CartProvider>
            </SearchProvider>
            {/* </FilterProvider> */}
        </PayPalScriptProvider>
    </CookieProvider>
);

