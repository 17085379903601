import React, { useEffect, useState } from "react";
import AdicionalList from "../AdicionalList/AdicionalList";
import axios from "axios";
import { FadeLoader } from "react-spinners";
import { collection, getDocs } from "firebase/firestore";
import { baseDeDatos } from "../../admin/FireBaseConfig";


const AdicionalListContainer = ({ onAdd }) => {

    const [adicional, setAdicional] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    
    useEffect(() => {
        const initAdicional = async () => {
            try {
                // Realiza la consulta a la colección "adicional" en Firestore
                const querySnapshot = await getDocs(collection(baseDeDatos, 'adicionales'));
                const adicionalData = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
                setAdicional(adicionalData);
            } catch (error) {
                console.log("No se pudo obtener la base de datos:", error);
            }
            setIsLoading(false);
        };
        initAdicional();
    }, []);


    return (
        <div key={adicional._id} className="adicionales">

            {isLoading ? (
                <>
                    <h1 className="loadProd">Cargando Adicionales, aguarde...</h1>
                    <FadeLoader className="fadeLoader" color="rgb(255, 255, 255)" />
                </>
            ) : (
                <>
                    <h2 className="titulo-adicional">Seleccione los Adicionales que desea agregar</h2>
                    <AdicionalList adicional={adicional} />
                </>
            )
            }

        </div>
    );

};
export default AdicionalListContainer;
