import React, { useEffect, useState } from 'react';
import { collection } from 'firebase/firestore';
import { baseDeDatos } from '../../admin/FireBaseConfig';
import { getDocs } from 'firebase/firestore';
import 'react-responsive-carousel/lib/styles/carousel.min.css'
// import { Carousel } from 'react-responsive-carousel';
import Carousel from 'react-bootstrap/Carousel';
import { NavLink } from 'react-router-dom';
import { FadeLoader } from 'react-spinners';
import { Box, Paper, Typography } from '@mui/material';
import './carousel.css'
import { useTheme } from '../../context/ThemeSwitchContext';

const CarouselComponent = () => {
  const [banners, setBanners] = useState([]);
  const { isDarkMode } = useTheme();
  const className = isDarkMode ? 'dark-mode' : '';
  const fetchBanners = async () => {
    const bannersCollection = collection(baseDeDatos, 'banners');
    const bannerDocs = await getDocs(bannersCollection);
    const bannerList = bannerDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setBanners(bannerList);

  };

  useEffect(() => {
    fetchBanners();
  }, []);



  return (
    <Paper elevation={24} className={`carousel-box ${className}`}>
    <div className={`carousel-container ${className}`}>
      {
        !banners ? (<div>
          <Typography variant='h5' color='black' sx={{ marginTop: '30px' }} >Cargando, aguarde....</Typography>
          <FadeLoader className="fadeLoader" color="black" />
        </div>)
          :
          <Carousel interval={3000} pause={false} data-bs-theme='light'>
            {banners.map((banner, index) => (
              <Carousel.Item key={index}>
                <NavLink className='nav-carousel' to={banner.ruta}>
                  <img src={banner.imagen} alt={banner.nombre} />
                  <Carousel.Caption>
                    <h1 className="legend">{banner.nombre}</h1>
                  </Carousel.Caption>
                </NavLink>
              </Carousel.Item>
            ))}
          </Carousel>
      }
    </div>
    </Paper>
  )
}

export default CarouselComponent