import React from 'react';
import CarouselComponent from "../Carousel/Carousel";
import ItemListContainer from "../ItemListContainer/ItemListContainer"
import { Helmet } from "react-helmet";
import MiddleMenu from '../MiddleMenu/MiddleMenu';
import { ThemeProvider, Typography, createTheme, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import HomeBanner from '../HomeBanner/HomeBanner';
import TopItems from '../ItemsTopSelling/TopItems';
import Informacion from '../Informacion/Informacion';
import HomeOcasiones from '../HomeOcasiones/HomeOcasiones';
import { useSpring, animated } from 'react-spring';
import { useInView } from 'react-intersection-observer';


const Home = () => {

    const theme = createTheme();
    const isSmallScreen = useMediaQuery('(max-width:900px)');

    theme.typography.h2 = {
        color: theme.palette.getContrastText(grey[900]),
        padding: 20,
        fontFamily: 'Carattere, sans-serif',
        fontSize: isSmallScreen ? '2.2rem' : '1.2rem',
        [theme.breakpoints.up('md')]: {
            fontSize: '2.4rem',
        },
    };

    const [refTheme, inViewTheme] = useInView({
        threshold: 0.1,  
    });

    const animationTheme = useSpring({
        opacity: inViewTheme ? 1 : 0,
        transform: inViewTheme ? 'translateY(0)' : 'translateY(100px)',
    });

    const [refTopItems, inViewTopItems] = useInView({
        threshold: 0.15,  
    });

    const animationTopItems = useSpring({
        opacity: inViewTopItems ? 1 : 0,
        transform: inViewTopItems ? 'translateY(0)' : 'translateY(50px)',
    });


    const [refTheme2, inViewTheme2] = useInView({
        threshold: 0.2,  
    });

    const animationTheme2 = useSpring({
        opacity: inViewTheme2 ? 1 : 0,
        transform: inViewTheme2 ? 'translateY(0)' : 'translateY(50px)',
    });



    return (
        <>
            <Helmet>
                <title>Envio Flores - Venta y envio de arreglos florales, comestibles, chocolates , bebidas y demás
                    a domicilio en el día
                    a Gran Buenos Aires y Capital Federal</title>
                <meta name="description" content="Envio de flores, rosas, ramos, bombones, regalos 
                    a domicilio en Argentina. Venta online y telefónica. Pagos en efectivo y 
                    con tarjetas de crédito. Entrega inmediata. Delivery en el día en Buenos Aires. Envioflores.com" />
            </Helmet>
            <HomeBanner />

            <animated.div ref={refTheme} style={animationTheme}>

                <ThemeProvider theme={theme}>
                    <Typography variant="h2" sx={{ color: '#a00303' }}> Nuestros productos más vendidos
                    </Typography>
                </ThemeProvider>
            </animated.div>

            <animated.div ref={refTopItems} style={animationTopItems}>

                <TopItems />
            </animated.div>
            <hr />
            <CarouselComponent />

            <div>
                <animated.div ref={refTheme2} style={animationTheme2}>

                    <ThemeProvider theme={theme}>
                        <Typography variant="h2" sx={{ color: '#a00303' }}> ¿ Qué deseas regalar hoy ?
                        </Typography>
                    </ThemeProvider>
                </animated.div>

                    <MiddleMenu />
            </div>
            <hr />
            <Informacion />

            <HomeOcasiones />

            <hr />
            <ThemeProvider theme={theme}>
                <Typography variant="h2" sx={{ color: '#a00303' }}> Estos son todos nuestros productos
                </Typography>
                <ItemListContainer />
            </ThemeProvider>


        </>
    );
};

export default Home;