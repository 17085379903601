import { Paper, Typography, useMediaQuery } from "@mui/material";
import React, { useEffect, useState } from "react";
import style from './informacion.module.css'



const Informacion = () => {

    const isSmallScreen = useMediaQuery('(max-width:650px)');

  //Detectar dispositivo:
  const [device, setDevice] = useState('');

    useEffect(() => {
        const userAgent = navigator.userAgent;
        if (/Android/i.test(userAgent)) {
          setDevice('Android');
        } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
          setDevice('iOS');
        } else {
          setDevice('PC');
        }
      }, []);

    return (

            <Paper elevation={24} sx={{
                backgroundImage: "url('/assets/imagenes/fondosHome/fondo-info.jpg')",
                backgroundSize: 'cover',
                WebkitBackgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundAttachment: device === 'iOS' ? 'initial' : 'fixed',
                color: 'white',
                display: 'flex', alignItems: 'center',
                flexDirection: 'column', flexWrap: 'wrap'
                , margin: '30px 0 50px',
            }} className={style.divInfo}>

        <div id={style.divInformacion}>


                <h1 className={style.text}>
                    ¿Por qué Envio Flores?
                </h1>
                <h3 className={style.text}>

                    Somos la florería online número uno.
                    Contamos con un equipo de floristas profesionales,
                    con amplia experiencia en el rubro, que se esforzarán al
                    máximo para cumplir con tus expectativas, y sorprender a
                    esa persona tan especial para ti. Realiza tu pedido de flores
                    online en tres simples pasos, y entregaremos tus flores a domicilio
                    en donde nos indiques. Puedes elegir la fecha y el horario de entrega de flores
                    luego de agregar un producto a tu carrito. Ante cualquier duda no dudes en comunicarte con nosotros!
                </h3>

                <div className={style.divPapers}>
                    <div className={style.paperMiniText}  >
                        <img src='/assets/imagenes/logo/Logo-rose.png' width={45}
                            height={45} alt="Icono"
                            style={{ borderRadius: '30px', marginBottom: '10px' }}
                        />
                        <h4 className={style.secondText}>
                            ELIGE LAS FLORES QUE MÁS TE GUSTEN
                        </h4>
                    </div>

                    <div className={style.paperMiniText}  >
                        <img src='/assets/imagenes/logo/Logo-rose.png' width={45}
                            height={45} alt="Icono"
                            style={{ borderRadius: '30px', marginBottom: '10px' }}
                        />

                        <h4 className={style.secondText}>
                            SELECCIONA UNA FECHA DE ENVIO Y ESCRIBE UNA DEDICATORIA
                        </h4>
                    </div>

                    <div className={style.paperMiniText}  >
                        <img src='/assets/imagenes/logo/Logo-rose.png' width={45}
                            height={45} alt="Icono"
                            style={{ borderRadius: '30px', marginBottom: '10px' }}
                        />
                        <h4 className={style.secondText}>
                            PAGA ONLINE CON TARJETA DE CREDITO
                        </h4>
                    </div>
                </div>

                <hr style={{border:'2px solid darkred', width:'100%'}}/>

                <div className={style.divEmpresasAsociadas}  >
                    <Typography variant="h3" className={style.secondText}>
                        EMPRESAS ASOCIADAS
                    </Typography>

            <div className={style.empresas}>

                    <Paper elevation={24} sx={{flex:'1', background: 'transparent' }}>
                        <Typography variant="h5" className={style.secondText}>
                            Aflorar
                        </Typography>
                        <a className={style.linkEmpresas}  href="https://www.aflorar.com.ar/">
                        <img src='/assets/imagenes/empresas-asociadas/Aflorar.png' width={280}
                            height={220} alt="Icono"
                            style={{ borderRadius: '7px', marginBottom: '10px' }}
                        />
                        </a>
                    </Paper>
                    <Paper elevation={24} sx={{flex:'1', background: 'transparent' }}>
                        <Typography variant="h5" className={style.secondText}>
                            Florerias Argentinas
                        </Typography>
                        <a className={style.linkEmpresas} href="https://floreriasargentinas.vercel.app/">
                        <img src='/assets/imagenes/empresas-asociadas/FloreriasArgentinas.png' width={280}
                            height={220} alt="Icono"
                            style={{ borderRadius: '7px', marginBottom: '10px' }}
                        />
                        </a>
                    </Paper>
                    <Paper elevation={24} sx={{flex:'1', background: 'transparent' }}>
                        <Typography variant="h5" className={style.secondText}>
                            Flores Express
                        </Typography>
                        <a className={style.linkEmpresas} href="https://www.floresexpress.com.ar/">
                        <img src='/assets/imagenes/empresas-asociadas/FloresExpress.png' width={280}
                            height={220} alt="Icono"
                            style={{ borderRadius: '7px', marginBottom: '10px' }}
                        />
                        </a>
                    </Paper>
                    <Paper elevation={24} sx={{flex:'1', background: 'transparent' }}>
                        <Typography variant="h5" className={style.secondText}>
                            Regalos Flores
                        </Typography>
                        <a className={style.linkEmpresas} href="https://www.regalosflores.com.ar/">
                        <img src='/assets/imagenes/empresas-asociadas/RegalosFlores.png' width={280}
                            height={220} alt="Icono"
                            style={{ borderRadius: '7px', marginBottom: '10px' }}
                        />
                        </a>
                    </Paper>
                    </div>


                </div>

                </div>

            </Paper>
    )
}


export default Informacion
