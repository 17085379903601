import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import ItemCount from '../ItemCount/ItemCount'
import { CartContext } from '../../context/CartContext'
import { Button, CardActions, useMediaQuery } from '@mui/material'
import { styled } from '@mui/material/styles';
import { green, grey, pink } from '@mui/material/colors'
import { ImportExportSharp } from '@mui/icons-material'
import { useInView } from 'react-intersection-observer'
import { useSpring, animated } from 'react-spring'

const Item = ({ items }) => {

  const { dolar, priceDolar } = useContext(CartContext)
  const onAdd = (optionId, optionSize, optionPrecio, quantity) => {
  };
  const isSmallScreen = useMediaQuery('(max-width:850px)');
  const isMobileSmallScreen = useMediaQuery('(max-width: 650px)');

  
  const dolarPrice = items.opciones[0].precio / dolar;
  const dolarPriceRounded = dolarPrice.toFixed(2);

  const ColorButton = styled(Button)(({ theme }) => ({
    color: 'success',
    fontFamily:'"Jost", sans-serif !important',
    backgroundColor: 'transparent',
    fontSize: isMobileSmallScreen ? '9.35px' : (isSmallScreen ? '12.45px' : '0.875rem'),
    fontWeight: '700',
    height: 'inherit',
    '&:hover': {
      backgroundColor: 'transparent',
      color: theme.palette.getContrastText(grey[50]),
      fontWeight: 500
    },
  }));

  const [ref, inView] = useInView({
    threshold: 0.125,  
  });

  const animation = useSpring({
    opacity: inView ? 1 : 0,
    transform: inView ? 'translateY(0)' : 'translateY(100px)',
  });

  return (

    <animated.div ref={ref} style={animation}>
    <div className="product-item">


      <Link to={`/detail/${items.id}`} className="link-img-producto">
        {
          items.opciones[0].img ?
            <img src={items.opciones[0].img} quality="auto" alt="" />
            :
            <img src={items.img} quality="auto" alt="" /> 
        }
      </Link>

      <h4 className="tituloProducto">{items.nombre}</h4>
      <div className="div-prod-details-indx">

        <p className="prod-details-index">Tamaño: <strong className='strong-prod-details'> {items.opciones[0].size} </strong></p>



        {
          priceDolar ?
            <p className='prod-details-index'>Precio:
              <strong className='strong-prod-price-details'>USD$ {dolarPriceRounded} </strong></p>
            :
            <p className="prod-details-index">Precio:
              <strong className='strong-prod-price-details'>  ${items.opciones[0].precio.toLocaleString('es-AR')} </strong> </p>

        }

      </div>

      <CardActions sx={{ padding: 0, display:'flex', flexDirection: isMobileSmallScreen ? 'row' : 'column' , marginTop: '15px' }}>
        {/* <Button size="small">Share</Button> */}
        <ItemCount
          idProd={items.id}
          optionSize={items.opciones[0].size}
          optionPrecio={items.opciones[0].precio}
          optionImg={items.opciones[0].img}
          item={items}
          onAdd={onAdd} />

        {
          items.opciones.length > 1
            ? <Link to={`/detail/${items.id}`} className="link-producto">
              <ColorButton color='success' >{ isSmallScreen ? 'Ver Más' : 'Ver Opciones'}</ColorButton>

            </Link>
            : null
        }
      </CardActions>
    </div>
    </animated.div>
  )
}

export default Item