import React, { useContext, useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { baseDeDatos } from '../../admin/FireBaseConfig';
import { Button, CardActions, useMediaQuery } from '@mui/material';
import ItemCount from '../ItemCount/ItemCount';
import { CartContext } from '../../context/CartContext';
import style from './topItems.module.css'
import { useTheme } from '../../context/ThemeSwitchContext';



export default function TopItems() {

    const { dolar, priceDolar } = useContext(CartContext);

    const isSmallScreen = useMediaQuery('(max-width:900px)');
    const isMobileSmallScreen = useMediaQuery('(max-width: 650px)');
    const [topItems, setTopItems] = useState([])
    const {isDarkMode} = useTheme()

    // PRODUCTOS
    const fetchProducts = async () => {
        const productsCollection = collection(baseDeDatos, 'productos');
        const productSnapshot = await getDocs(productsCollection);
        const productList = productSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

        // Ordenar los productos por ventas en el lado del cliente
        const sortedProducts = productList.sort((a, b) => b.vendidos - a.vendidos);

        // Tomar solo los primeros 5 productos
        const top5Products = sortedProducts.slice(0, 5);

        setTopItems(top5Products);
    };



    useEffect(() => {
        fetchProducts();
    }, []);



    return (

        <div>

            <Box sx={{ display: 'flex', flexWrap: 'wrap', margin: '15px 20px', justifyContent:'center', alignItems:'stretch', background:isDarkMode && 'white', borderRadius:isDarkMode && '20px' }} className={style.boxTopProds}>

                {topItems && topItems?.map((items) => (
                    <div className={style.topProductsDiv} key={items.id}>


                        <a href={`/detail/${items.id}`} className="link-img-producto">
                            <img src={items?.opciones[0].img} quality="auto" alt="" style={{borderRadius:'15px'}} width={220} height={220} />

                        </a>
                        <h6 style={{ color: 'grey' }}>Veces vendido: {items.vendidos} vendidos</h6>

                        <h4 className="tituloProducto">{items.nombre}</h4>
                        <div className="div-prod-details-indx">

                            <p className="prod-details-index">Tamaño: <strong className='strong-prod-details'> {items.opciones[0].size} </strong></p>



                            {
                                priceDolar ?
                                    <p className='prod-details-index'>Precio:
                                        <strong className='strong-prod-price-details'>USD$ {(items.opciones[0].precio / dolar).toFixed(2)} </strong></p>
                                    :
                                    <p className="prod-details-index">Precio:
                                        <strong className='strong-prod-price-details'>  ${items.opciones[0].precio.toLocaleString('es-AR')} </strong> </p>

                            }

                        </div>

                        <CardActions sx={{ padding: 0, display: 'flex', flexDirection: isMobileSmallScreen ? 'row' : 'column', marginTop: '15px' }}>
                            <ItemCount
                                optionId={items.opciones[0].id}
                                optionSize={items.opciones[0].size}
                                optionPrecio={items.opciones[0].precio}
                                optionImg={items.opciones[0].img}
                                item={items}
                                topProducts={true}
                            />
                        </CardActions>
                    </div>
                ))}
            </Box>


        </div>

    );
}