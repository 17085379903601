/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import axios from 'axios';
import { collection, getDocs } from 'firebase/firestore';
import { createContext, useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { baseDeDatos } from '../admin/FireBaseConfig';
import nacl from 'tweetnacl';
import naclUtil from 'tweetnacl-util';
import { User } from 'mercadopago';

export const CartContext = createContext();

const generateRandomCode = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
  };
  
  const getOrCreateUniqueCode = (storage, key, expirationTime) => {
    const code = storage.getItem(key);
    if (!code || (Date.now() - parseInt(code.split('_')[1]) > expirationTime)) {
      const newCode = generateRandomCode(10) + '_' + Date.now();
      storage.setItem(key, newCode);
      return newCode.split('_')[0]; // Return only the code without the timestamp
    }
    return code.split('_')[0]; // Return only the code without the timestamp
  };

const CartProvider = ({ children }) => {

    const cartLocalStorage = localStorage.getItem('c', [])
    const [cart, setCart] = useState(JSON.parse(cartLocalStorage) || []);
    let precioTotal = 0
    const [finalPrice, setFinalPrice] = useState(0)
    const [locationValue, setLocationValue] = useState(0)
    const [location, setLocation] = useState({})
    const [locationName, setLocationName] = useState("")

    // //Envio Premium
    const [premiumAdded, setPremiumAdded] = useState(false);
    const [isPremium, setIsPremium] = useState(false);

    const key = nacl.randomBytes(32);

    const encryptCart = (cartData) => {
        const nonce = nacl.randomBytes(24);
        const encryptedData = nacl.secretbox(
            naclUtil.decodeUTF8(JSON.stringify(cartData)),
            nonce,
            key
        );
        const encryptedDataString = naclUtil.encodeBase64(encryptedData);
        return {
            nonce: naclUtil.encodeBase64(nonce),
            data: encryptedDataString,
            key: naclUtil.encodeBase64(key),
        };
    };

    const decryptCart = (encryptedCart) => {
        try {
            if (!encryptedCart) {
                return [];
            }

            const { nonce, key, data } = encryptedCart;

            if (!nonce || !key || !data) {
                return [];
            }

            const decodedNonce = naclUtil.decodeBase64(nonce);
            const decodedKey = naclUtil.decodeBase64(key);
            const decodedData = naclUtil.decodeBase64(data);

            const decryptedData = nacl.secretbox.open(decodedData, decodedNonce, decodedKey);

            if (!decryptedData) {
                throw new Error('Error al desencriptar los datos del carrito.');
            }

            const decryptedDataString = naclUtil.encodeUTF8(decryptedData);
            return JSON.parse(decryptedDataString);
        } catch (error) {
            console.error('Error al desencriptar el carrito:', error);
            return [];
        }
    };


    useEffect(() => {
        const storedEncryptedCart = localStorage.getItem('c');
        const decryptedCart = decryptCart(JSON.parse(storedEncryptedCart));
        setCart(decryptedCart);
    }, []);


    useEffect(() => {
        localStorage.setItem('c', JSON.stringify(encryptCart(cart)));
    }, [cart, encryptCart]);

    //
    const [costos, setCostos] = useState([]);
    const fetchCosts = async () => {
        const costosRef = collection(baseDeDatos, 'costos');
        const costosSnapShot = await getDocs(costosRef);
        const costosData = [];
        costosSnapShot.forEach((doc) => {
            costosData.push({ id: doc.id, ...doc.data() });
        });
        setCostos(costosData);
    };

    const costoEnvios = costos.filter(cost => cost.categoria.includes("envios"))
    const precioEnvioPremium = Number(costoEnvios[0]?.precio)

    useEffect(() => {
        fetchCosts();
    }, [setCostos]);

    //Precio Rosas
    const costoRosa = costos.filter(cost => cost.categoria.includes("rosas"))
    const rose_unit = Number(costoRosa[0]?.precio)


    //Precio Del Dolar
    const costoDolar = costos.filter(cost => cost.categoria.includes("moneda"))
    const dolar = Number(costoDolar[0]?.precio)

    const [priceDolar, setPriceDolar] = useState(false)

    //Costo de envio en DOLARES
    const envioPremiumInUsd = Number((precioEnvioPremium / dolar).toFixed(2))
    //Productos y Carrito



    const totalPrecio = () => {
        let acumulador = 0;
        let acumuladorInUsd = 0;

        // Obtenemos el carrito de localStorage
        const storedEncryptedCart = localStorage.getItem('c');
        // Desencriptamos el carrito antes de usarlo
        const decryptedCart = decryptCart(JSON.parse(storedEncryptedCart));

        // Verifica si decryptedCart es un array
        if (Array.isArray(decryptedCart)) {
            // Calcula el precio total de los productos en el carrito
            decryptedCart.forEach((prod) => {
                const precioInUsd = (prod.precio / dolar).toFixed(2);
                acumuladorInUsd += prod.quantity * precioInUsd;
                acumulador += prod.quantity * prod.precio;
            });
        }

        precioTotal = priceDolar ? acumuladorInUsd : acumulador;

        if (isPremium && !priceDolar) {
            // Si se seleccionó el servicio Premium, agrega el precio del envío premium
            precioTotal += precioEnvioPremium + locationValue;
        } else if (isPremium && priceDolar) {
            // Si se seleccionó el servicio Premium, agrega el precio del envío premium
            precioTotal += envioPremiumInUsd + locationValue;
            precioTotal = parseFloat(precioTotal.toFixed(2));
        } else if (!isPremium && priceDolar) {
            // Si NO se seleccionó el servicio Premium, pero si cambia el precio del Dolar.
            precioTotal += locationValue;
            precioTotal = parseFloat(precioTotal.toFixed(2));

        } else if (!isPremium) {
            precioTotal += locationValue;
        }

        return precioTotal;
    };

    useEffect(() => {
        // Actualiza finalPrice con el precio total incluyendo el envío premium
        setFinalPrice(totalPrecio());
    }, [cart, isPremium, totalPrecio, precioTotal, priceDolar, dolar, locationValue, precioEnvioPremium]);



    const eliminarProd = async (name, size, precio, quantity) => {
        try {

            // Find the index of the product in the cart array
            const productIndex = cart.findIndex(prod => (prod.precio === precio) && (prod.size === size) && (prod.name === name));

            // Check if the product is found in the cart
            if (productIndex !== -1) {
                if (cart[productIndex].quantity > 1) {
                    // Ask the user how much quantity to remove
                    const { value: quantityToRemove } = await Swal.fire({
                        title: 'Cantidad a eliminar',
                        input: 'number',
                        inputLabel: 'Ingrese la cantidad que desea eliminar',
                        inputValue: 1,
                        inputValidator: (value) => {
                            if (parseInt(value) <= 0 || parseInt(value) > cart[productIndex].quantity) {
                                return 'Ingrese una cantidad válida!';
                            }
                        }
                    });

                    // If the user cancels, do nothing
                    if (!quantityToRemove) return;

                    // Update the quantity in the cart
                    cart[productIndex].quantity -= parseInt(quantityToRemove);

                    // If the remaining quantity is greater than zero, update the cart state
                    if (cart[productIndex].quantity > 0) {
                        setCart([...cart]);
                    } else {
                        // If the remaining quantity is zero, remove the product from the cart
                        const updatedCart = cart.filter(prod => (prod.size !== size) || (prod.precio !== precio));
                        setCart(updatedCart);
                    }
                } else {
                    // If there is only one quantity of the product, remove it from the cart
                    const updatedCart = cart.filter(prod => (prod.size !== size) || (prod.precio !== precio));
                    setCart(updatedCart);
                }

                // Show a success message
                Swal.fire({
                    icon: 'warning',
                    title: 'Producto eliminado!',
                    text: 'El producto ha sido eliminado de tu carrito.',
                    toast: true,
                    position: 'bottom-right',
                    showConfirmButton: false,
                    timer: 2100,
                    timerProgressBar: true,
                    background: '#f3f3f3',
                    iconColor: '#a30000'
                });
            }
        } catch (error) {
            console.log("Ocurrió un error en el Contexto del Cart", error);
        }
    };



    const cantidadProducto = (id) => {
        const producto = cart?.find((prod) => prod.id === id);
        return producto?.quantity;
    };

    // useEffect(() => {
    //     if (localStorage.getItem('cart')) {
    //         localStorage.removeItem('cart');
    //     }
    //     if (localStorage.getItem("CartID", CartID)) {
    //         localStorage.removeItem("CartID", CartID)
    //     }

    //     if (localStorage.getItem("UserID", UserID)) {
    //         localStorage.removeItem("UserID", UserID)
    //     }
    // }, []);


// // COOKIES
//     const [CartID, setCartID] = useState(() => {
//         return getOrCreateUniqueCode(localStorage, 'CartID', 24 * 60 * 60 * 1000); // 24 hours expiration
//       });
    
//       const [UserID, setUserID] = useState(() => {
//         return getOrCreateUniqueCode(localStorage, 'UserID', 24 * 60 * 60 * 1000); // 24 hours expiration
//       });

//     useEffect(() => {

//         localStorage.setItem("CartID", CartID);
//         localStorage.setItem("UserID", UserID);
//     }, [CartID, UserID]);


    return (
        <CartContext.Provider
            value={{
                cart,
                cartLocalStorage,
                setCart,
                encryptCart,
                decryptCart,
                rose_unit,
                setPriceDolar,
                priceDolar,
                eliminarProd,
                cantidadProducto,
                totalPrecio,
                setLocationValue,
                locationValue,
                setLocation,
                location,
                setLocationName,
                locationName,
                envioPremiumInUsd,
                dolar,
                finalPrice,
                setFinalPrice,
                precioEnvioPremium,
                setPremiumAdded,
                premiumAdded,
                isPremium,
                setIsPremium,
                // CartID,
                // UserID
            }}>

            {children}
        </CartContext.Provider>
    );
};

export default CartProvider;
