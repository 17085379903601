import React, { createContext, useState, useEffect } from 'react';

// Crea el contexto de cookies
export const CookieContext = createContext();

const generateRandomCode = (length) => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * characters.length));
    }
    return result;
};

const getOrCreateUniqueCode = (storage, key, expirationTime) => {
    const code = storage.getItem(key);
    if (!code || (Date.now() - parseInt(code.split('_')[1]) > expirationTime)) {
        const newCode = generateRandomCode(10) + '_' + Date.now();
        storage.setItem(key, newCode);
        return newCode.split('_')[0]; // Return only the code without the timestamp
    }
    return code.split('_')[0]; // Return only the code without the timestamp
};


// Crea el proveedor de cookies
export const CookieProvider = ({ children }) => {

    // COOKIES
    const [acceptedCookies, setAcceptedCookies] = useState(false);
    
    // Cookie Prompt
    const [showCookiePrompt, setShowCookiePrompt] = useState(false);

    // CARTID & USERID
    const [CartID, setCartID] = useState(() => {
        return getOrCreateUniqueCode(localStorage, 'CartID', 24 * 60 * 60 * 1000); // 24 hours expiration
    });
    const [UserID, setUserID] = useState(() => {
        return getOrCreateUniqueCode(localStorage, 'UserID', 24 * 60 * 60 * 1000); // 24 hours expiration
    });

    // Verifica si el valor de "accepted Cookies" está en el localStorage al cargar la página
    useEffect(() => {
        const storedAcceptedCookies = localStorage.getItem('acceptedCookies');
        if (storedAcceptedCookies) {
            setAcceptedCookies(true);
        }
    }, []);

    // Función para aceptar las cookies y guardar el valor en el localStorage
    const acceptCookies = () => {
        localStorage.setItem('acceptedCookies', 'true');
        localStorage.setItem("CartID", CartID);
        localStorage.setItem("UserID", UserID);
        setAcceptedCookies(true);
    };


    // Verifica si las cookies existen al montar un componente
    useEffect(() => {
        const storedCartID = localStorage.getItem('CartID');
        const storedUserID = localStorage.getItem('UserID');
        const storedAcceptedCookies = localStorage.getItem('acceptedCookies');

        if (!storedCartID || !storedUserID || !storedAcceptedCookies) {
            // Crea una alerta o aviso de que debe aceptar las cookies y setea como false acceptedCookies
             setShowCookiePrompt(true);
             setAcceptedCookies(false);
             

        }
    }, []);


    return (
        <CookieContext.Provider value={{
            acceptedCookies,
            acceptCookies,
            setAcceptedCookies,
            CartID,
            UserID,
            showCookiePrompt, 
            setShowCookiePrompt
        }}>
            {children}
        </CookieContext.Provider>
    );
};