import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { collection, getDocs } from 'firebase/firestore';
import { baseDeDatos } from '../../admin/FireBaseConfig';
import { useMediaQuery } from '@mui/material';
import { NavLink } from 'react-router-dom';
import './middlemenu.css'
import { useTheme } from '../../context/ThemeSwitchContext';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';


export default function MiddleMenu() {


  const [banners, setBanners] = useState([]);
  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const { isDarkMode } = useTheme();
  
  //Detectar dispositivo:
  const [device, setDevice] = useState('');

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      setDevice('Android');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDevice('iOS');
    } else {
      setDevice('PC');
    }
  }, []);


  const fetchBanners = async () => {
    const bannersCollection = collection(baseDeDatos, 'banners');
    const bannerDocs = await getDocs(bannersCollection);
    const bannerList = bannerDocs.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    setBanners(bannerList);
  };

  useEffect(() => {
    fetchBanners();
  }, []);

  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 250,
    margin: '4px',
    borderRadius:'5px',

    [theme.breakpoints.down('sm')]: {
      width: '100% !important', 
      height: 125,
    },
    backgroundImage: 'url()',
    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        transition: 'border .085s ease-in-out',
        border: '4px solid currentColor',
      },
    },
  }));

  const Image = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    background:'linear-gradient(to bottom, black, transparent)',
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  }));

  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));


  const [refMiddleMenu, inViewMiddleMenu] = useInView({
    threshold: 0.2,  
});

const animationMiddleMenu = useSpring({
    opacity: inViewMiddleMenu ? 1 : 0,
    transform: inViewMiddleMenu ? 'translateY(0)' : 'translateY(50px)',
});


  return (

    <animated.div ref={refMiddleMenu} style={animationMiddleMenu}>
    <Box sx={{ display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%',padding: '2px 0px', background:isDarkMode && 'black' }}>
      {banners.map((image) => (
        <ImageButton
        className='imagen-Button'
          focusRipple
          key={image.nombre}
          style={{
            width: '49%',
            backgroundImage: `url(${image.imagen})`,
            backgroundAttachment: device === 'iOS' ? 'initial' : 'fixed',

          }}
        >
          <ImageBackdrop className="MuiImageBackdrop-root" />
          <Image>
            <NavLink className='link-middle-images' to={image.ruta}>
              <Typography
                component="span"
                variant="subtitle"
                color="inherit"
                sx={{
                  position: 'relative',
                  p: 4,
                  pt: 2,
                  fontWeight: 800,
                  fontSize: isSmallScreen ? 15 : 20,
                  pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                }}
              >
                {image.nombre}
                <ImageMarked className="MuiImageMarked-root" />
              </Typography>
            </NavLink>
          </Image>
        </ImageButton>
      ))}
    </Box>
</animated.div>
 
  );
}