import React, { useState, useEffect, useRef, memo } from 'react';
import { Typography, useMediaQuery } from '@mui/material';
import style from './homeBanner.module.css';
import { TypeAnimation } from 'react-type-animation';
import { baseDeDatos } from '../../admin/FireBaseConfig';
import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import Typed from 'typed.js';
import { useTheme } from '../../context/ThemeSwitchContext';


const HomeBanner = memo(() => {
    const [location, setLocation] = useState('Buenos Aires'); // Localidad predeterminada
    const [text, setText] = useState('');
    const [index, setIndex] = useState(0);
    const isSmallScreen = useMediaQuery('(max-width:650px)');

    
    // Estado para la imagen de fondo
    const [backgroundImage, setBackgroundImage] = useState('');

    //DarkMode
    const {isDarkMode} = useTheme();
    const className = isDarkMode ? 'dark-mode' : '';


    //direcciones
    const [directions, setDirections] = useState([]);

    const fetchDirections = async () => {
        const directionsRef = collection(baseDeDatos, 'direcciones');
        const orderedQuery = query(directionsRef, orderBy('name')); // Ordena por el campo 'nombre'

        const directionsSnapshot = await getDocs(orderedQuery);
        const directionsData = [];
        directionsSnapshot.forEach((doc) => {
            directionsData.push({ id: doc.id, ...doc.data() });
        });
        setDirections(directionsData);
    };

    useEffect(() => {
        fetchDirections();
    }, []);

    let nameDirecs = directions.flatMap(name => { return (name.name) })

    // Array de imágenes de fondo
    const lightBackgroundImages = [
        '/assets/imagenes/fondosHome/fondo-inicio.png',
        '/assets/imagenes/fondosHome/fondo-inicio2.png',
        '/assets/imagenes/fondosHome/fondo-inicio3.png',
        '/assets/imagenes/fondosHome/fondo-inicio4.png',
        '/assets/imagenes/fondosHome/fondo-inicio5.png',
        '/assets/imagenes/fondosHome/fondo-inicio6.png',
        '/assets/imagenes/fondosHome/fondo-inicio7.png',
        '/assets/imagenes/fondosHome/fondo-inicio8.png',
        '/assets/imagenes/fondosHome/fondo-inicio9.png',
        '/assets/imagenes/fondosHome/fondo-inicio10.png',
        '/assets/imagenes/fondosHome/fondo-inicio11.png',
        '/assets/imagenes/fondosHome/fondo-inicio12.png',
        '/assets/imagenes/fondosHome/fondo-inicio13.png',
        '/assets/imagenes/fondosHome/fondo-inicio14.png',
        '/assets/imagenes/fondosHome/fondo-inicio15.png',
    ];

    const darkBackgroundImages = [
        '/assets/imagenes/fondosHome/fondo-inicio17.png',
        '/assets/imagenes/fondosHome/fondo-inicio18.png',
        '/assets/imagenes/fondosHome/fondo-inicio20.png',
        '/assets/imagenes/fondosHome/fondo-inicio19.png',
        '/assets/imagenes/fondosHome/fondo-inicio16.png',
    ];

 // Función para obtener una imagen de fondo aleatoria
 const getRandomBackgroundImage = () => {
    const images = isDarkMode ? darkBackgroundImages : lightBackgroundImages;
    const randomIndex = Math.floor(Math.random() * (images.length - 1) + 1);
    return images[randomIndex];
};

// Efecto para cambiar la imagen de fondo cuando cambia el tema
useEffect(() => {
    setBackgroundImage(getRandomBackgroundImage());
}, [isDarkMode]);


    // Create reference to store the DOM element containing the animation
    const nameDirec = useRef(null);
    useEffect(() => {
        const prefixTexts = [
            "Envio Flores a domicilio en Capital Federal",
            "Envio Flores a domicilio en Buenos Aires"
        ];

        const formattedDirections = nameDirecs.map(direction => `Envio Flores a domicilio en ${direction}`);

        const allTexts = [...prefixTexts, ...formattedDirections];

        const typed = new Typed(nameDirec.current, {
            strings: allTexts, // Aquí pasamos el array combinado de textos
            typeSpeed: 80,
            fadeOut: true,
            loop: true,
            showCursor: false,

            // Puedes agregar más opciones de configuración según lo necesites
        });

        return () => {
            // Destroy Typed instance during cleanup to stop animation
            typed.destroy();
        };
    }, [nameDirecs]); // Asegúrate de incluir nameDirecs en las dependencias del efecto


        //Detectar dispositivo:
        const [device, setDevice] = useState('');

        useEffect(() => {
            const userAgent = navigator.userAgent;
            if (/Android/i.test(userAgent)) {
                setDevice('Android');
            } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
                setDevice('iOS');
            } else {
                setDevice('PC');
            }
        }, []);
    
    return (
        <div className={style.homeBanner}>

        <div className={style.divHomeBanner} style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'center center',
            backgroundAttachment: device === 'iOS' ? 'initial' : 'fixed',
            backgroundSize: 'cover',
            WebkitBackgroundSize: 'cover', // Prefijo específico para Safari
        }}>

            <span ref={nameDirec}
                style={{
                    fontSize: isSmallScreen ? '1.8rem' : '3.3rem', fontWeight: '600',
                    display: 'inline-block', position: 'absolute',
                    top: '100px',
                    color: 'white', left: '0', padding: '0 20px', width: '100%',
                }}
                className={style.typography} />



            <div className={style.divTypography} style={{ padding: '0 20px', }}>
                <TypeAnimation

                    preRenderFirstString={true}

                    sequence={[

                        ` Nuestra florería online ofrece las mejores flores a domicilio. 
                          Realizá tu compra online en tres simples pasos, y aboná con cualquier 
                          medio de pago al finalizar.` ,
                        1000,
                    ]}
                    wrapper="span"
                    speed={50}
                    style={{
                        fontSize: isSmallScreen ? '1rem' : '1.2rem', display: 'inline-block', position: 'relative',
                        fontWeight: '800', color: 'black',
                        top: '100px',
                        padding: '25px', borderRadius: '4px',
                        background: '#ffffffb5',
                    }}
                    className={style.typography}
                    repeat={Infinity}
                />

            </div>
        </div>
        </div>

    );
});

export default HomeBanner;
