import React from 'react';

function PendingPage() {
  return (
    <div>
      <h1>Compra esta en estado Pendiente</h1>
      <p>Tu compra aún no ha sido procesada correctamente. Espera unos segundos...</p>
    </div>
  );
}

export default PendingPage;
