import React, { useState, useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import AdicionalCount from '../AdicionalCount/AdicionalCount';
import { Checkbox } from '@mui/material';

const AdicionalItem = ({ adicional }) => {
    const { addAdicional, priceDolar, dolar } = useContext(CartContext);
    const [optionsSelected, setOptionsSelected] = useState([]);
    
    let totalAdic

    const handleChange = (id) => {
        const selectedOption = adicional.opciones.find(option => option.id === id);
        const exists = optionsSelected.some(option => option.id === id);
        
        if (exists) {
            setOptionsSelected(prevOptions => prevOptions.filter(option => option.id !== id));
        } else {
            setOptionsSelected(prevOptions => [...prevOptions, selectedOption]);
        }
    };

    const onAdd = () => {
        optionsSelected.forEach(option => {
            addAdicional(adicional.id, option.size, option.precio);
        });
    };
    const totalPrice = () => {
        totalAdic = optionsSelected.reduce((acc, option) => acc + Number(option.precio), 0);
        const totalAdicInUsd = (totalAdic / dolar).toFixed(2)

        if (priceDolar) {
            return totalAdicInUsd
        } else {
            return totalAdic
        }
    };

    return (
        <div className='div-adicional'>
            <img src={adicional.img} alt="" />
            <h4 className="titulo-adic">{adicional.nombre}</h4>

            {
                adicional.opciones.map(({ size, precio, id }, index) => {
                    const precioInUsd = (precio / dolar).toFixed(2)
                    return (
                        <div className='div-info-adicionales' key={`${id}-${index}`}>
                            <Checkbox
                                color='success'
                                type='checkbox'
                                id={`custom-checkbox${id}`}
                                name={size}
                                onChange={() => handleChange(id)}
                            />
                            <label className='label-adic' htmlFor={`custom-checkbox${id}`}>{size} -
                                {
                                    priceDolar ? `USD ${precioInUsd}` : ` $ ${precio.toLocaleString('es-AR')}`
                                }
                            </label>
                        </div>
                    )
                })
            }
            <hr />
            <div >
                <h5 className='opt-select-adic'>Opciones seleccionadas:</h5>
                {optionsSelected.map(option => {
                    const optionPriceUsd = (option.precio / dolar).toFixed(2)
                    return (
                        <div className='opt-selected-adic' key={option.id}>

                            <p className='opt-selected-size'> {option.size}: </p>
                            {
                                priceDolar ?
                                    <p className='strong-prod-priceAdic-details'> USD ${optionPriceUsd}</p>
                                    :
                                    <p className='strong-prod-priceAdic-details'> $ {option.precio.toLocaleString('es-AR')}</p>
                            }
                        </div>
                    )
                })}
                {
                    totalPrice() !== 0 && <div className='div-total-adic'> <hr />
                        {
                            priceDolar ? <p className='total-adic'>Total: USD ${totalPrice()}</p>
                                : <p className='total-adic'>Total: $ {totalPrice().toLocaleString('es-AR')}</p>
                        }
                    </div>
                }
            </div>
            {optionsSelected.length > 0 && (
                <AdicionalCount
                    adicId={optionsSelected.id}
                    optionsSelected={optionsSelected}
                    img={adicional.img}
                    adicional={adicional}
                    onAdd={onAdd}
                    stock={3}
                    key={adicional.id}
                />
            )}
        </div>
    )
};

export default AdicionalItem;
