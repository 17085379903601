import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Typography from '@mui/material/Typography';
import { useMediaQuery } from '@mui/material';
import style from './homeOcasiones.module.css'
import { useTheme } from '../../context/ThemeSwitchContext';
import { useInView } from 'react-intersection-observer';
import { useSpring, animated } from 'react-spring';

export default function HomeOcasiones() {

  const isSmallScreen = useMediaQuery('(max-width:900px)');
  const { isDarkMode } = useTheme();
  
  //Detectar dispositivo:
  const [device, setDevice] = useState('');


  const ImageButton = styled(ButtonBase)(({ theme }) => ({
    position: 'relative',
    height: 250,
    margin: '4px',
    borderRadius: '5px',

    [theme.breakpoints.down('sm')]: {
      width: '100% !important', // Overrides inline-style
      height: 125,
    },
    backgroundImage: 'url()',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundAttachment: device === 'iOS' ? 'initial' : 'fixed',
    backgroundSize: 'cover',
    WebkitBackgroundSize: 'cover', // Prefijo específico para Safari

    '&:hover, &.Mui-focusVisible': {
      zIndex: 1,
      '& .MuiImageBackdrop-root': {
        opacity: 0.15,
      },
      '& .MuiImageMarked-root': {
        opacity: 0,
      },
      '& .MuiTypography-root': {
        transition: 'border .085s ease-in-out',
        border: '4px solid currentColor',
      },
    },
  }));

  const ImagenUI = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    borderRadius: '5px',

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: theme.palette.common.white,
  }));

  const ImageBackdrop = styled('span')(({ theme }) => ({
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    borderRadius: '5px',

    bottom: 0,
    background: 'linear-gradient(to bottom, grey, black)',
    opacity: 0.6,
    transition: theme.transitions.create('opacity'),
  }));

  const ImageMarked = styled('span')(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: 'absolute',
    bottom: -2,
    left: 'calc(50% - 9px)',
    transition: theme.transitions.create('opacity'),
  }));



  const ocasiones = [

    {
      imagen: '/assets/imagenes/ocasiones/ocasiones_15anos.jpg',
      nombre: 'Celebraciones',
      ruta: '',
    },
    {
      imagen: '/assets/imagenes/ocasiones/ocasiones_agradecimiento.jpg',
      nombre: 'Agradecimientos',
      ruta: '',
    },
    {
      imagen: '/assets/imagenes/ocasiones/ocasiones_aniversario.webp',
      nombre: 'Aniversarios',
      ruta: '',
    },
    {
      imagen: '/assets/imagenes/ocasiones/ocasiones_cumpleanos.jpg',
      nombre: 'Cumpleaños',
      ruta: '',
    },
    {
      imagen: '/assets/imagenes/ocasiones/ocasiones_maternidad.jpg',
      nombre: 'Nacimientos',
      ruta: '',
    },
    {
      imagen: '/assets/imagenes/ocasiones/ocasiones_condolencias.jpg',
      nombre: 'Condolencias',
      ruta: '',
    },
  ]

  useEffect(() => {
    const userAgent = navigator.userAgent;
    if (/Android/i.test(userAgent)) {
      setDevice('Android');
    } else if (/iPhone|iPad|iPod/i.test(userAgent)) {
      setDevice('iOS');
    } else {
      setDevice('PC');
    }
  }, []);


  const [refOcasiones, inViewOcasiones] = useInView({
    threshold: 0.2,  
});

const animationOcasiones = useSpring({
    opacity: inViewOcasiones ? 1 : 0,
    transform: inViewOcasiones ? 'translateY(0)' : 'translateY(100px)',
});

const [refOcasiones2, inViewOcasiones2] = useInView({
    threshold: 0.2,  
});

const animationOcasiones2 = useSpring({
    opacity: inViewOcasiones2 ? 1 : 0,
    transform: inViewOcasiones2 ? 'translateY(0)' : 'translateY(100px)',

});

  return (

    <div>

    <animated.div ref={refOcasiones} style={animationOcasiones}>
      <Typography variant="h2" sx={{
        color: 'darkred',
        textAlign: '-webkit-center', fontFamily: 'Carattere,sans-serif'
      }}>
        Todas las ocasiones
      </Typography>
    </animated.div>

      <animated.div ref={refOcasiones2} style={animationOcasiones2}>
      <Box sx={{
        display: 'flex', flexWrap: 'wrap', minWidth: 300, width: '100%', padding: '2px 0px', background: isDarkMode && 'black'
      }}>
        {ocasiones.map((image) => (
          <ImageButton
            className={style.imagenButton}
            focusRipple
            key={image.nombre}
            style={{
              width: '49%',
              backgroundImage: `url(${image.imagen})`,
            }}
          >
            <ImageBackdrop className="MuiImageBackdrop-root" />
            <ImagenUI>
              <a className={style.linkMiddleImages} href={image.ruta}>
                <Typography
                  component="span"
                  variant="subtitle"
                  color="inherit"
                  sx={{
                    position: 'relative',
                    p: 4,
                    pt: 2,
                    fontWeight: 800,
                    fontSize: isSmallScreen ? 15 : 20,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    transition: 'color .55s ease-in-out, background .33s ease, border-radius .9s ease-in',
                    '&:hover': {
                      borderRadius: '5px',
                    }
                  }}
                >
                  {image.nombre}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </a>
            </ImagenUI>
          </ImageButton>
        ))}
      </Box>
      </animated.div>
    </div>

  );
}