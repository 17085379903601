import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import ItemCount from '../ItemCount/ItemCount';
import estilosDetail from './ItemDetail.module.css';
import { useContext } from 'react';
import { CartContext } from '../../context/CartContext';
import AdicionalListContainer from '../AdicionalListContainer/AdicionalListContainer';
import Swal from 'sweetalert2';
import { Helmet } from 'react-helmet';

//Material Ui
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import { Avatar, Button, Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { grey, pink } from '@mui/material/colors';
import styled from '@emotion/styled';
import Categorization from '../Categories/Categorizacion';
import { useTheme } from '../../context/ThemeSwitchContext';
import { collection, getDocs } from 'firebase/firestore';
import { baseDeDatos } from '../../admin/FireBaseConfig';


const ItemDetail = ({ item }) => {

  const navigate = useNavigate();
  const { cantidadProducto, dolar, priceDolar, rose_unit } = useContext(CartContext);
  const [tipoProductoSeleccionado, setTipoProductoSeleccionado] = useState("");
  const [optionID, setOptionID] = useState('')
  const [precioTotal, setPrecioTotal] = useState(0);
  const [cantidadRosas, setCantidadRosas] = useState("");
  const prodOptions = item.opciones;
  const [showManualAdd, setShowManualAdd] = useState(true);
  const { prodId } = useParams()
  const quantity = cantidadProducto(prodId);
  const [selectedOption, setSelectedOption] = useState(null);

  const { isDarkMode } = useTheme()

  const isSmallScreen = useMediaQuery('(max-width:855px)');

  const CustomButton = styled(Button)(() => ({
    // backgroundColor: 'transparent',
    color: isDarkMode ? 'white' : 'darkred',
    fontSize: isSmallScreen ? '8px' : '12px',
    height: 'inherit',
    border: isDarkMode ? '1px solid white' : '1px solid darkred',
    transition: '.25s ease-in',
    '&:hover': {
      border: isDarkMode ? '1px solid red' : '1px solid white',
      fontWeight: 500
    },
  }));

  const precioTotalEnUsd = (precioTotal / dolar).toFixed(2)
  const [isActive, setIsActive] = useState(false);

  const handleClick = () => {
    setIsActive(!isActive);
  };


  //CAMBIAR ESTILO DE PRODUCTO
  const opcionesDisponiblesPorTipo = {
    Florero: ['Canasta'],
    Ramo: ['Florero', 'Canasta'],
    Caja: [],
    Canasta: [],
    Arreglo: [],
    Especiales: []
  };

  const [costos, setCostos] = useState([]);

  const fetchCosts = async () => {
    const costosRef = collection(baseDeDatos, 'costos');
    const costosSnapShot = await getDocs(costosRef);
    const costosData = [];
    costosSnapShot.forEach((doc) => {
      costosData.push({ id: doc.id, ...doc.data() });
    });
    setCostos(costosData);
  };

  const cambioProducto = costos.filter(cost => cost.categoria.includes("cambioProducto"))

  const [costosCanasta, setCostosCanasta] = useState(0);
  const [costosFlorero, setCostosFlorero] = useState(0);

  useEffect(() => {
    fetchCosts();
  }, [setCostos]);


  useEffect(() => {
    cambioProducto.forEach((costo) => {
      if (costo.nombre === "Canasta") {
        setCostosCanasta(costo.precio);
      }
      if (costo.nombre === "Florero") {
        setCostosFlorero(costo.precio);
      }
    });
  }, [costos]);

  const preciosProductos = {
    Arreglo: 1000,
    Ramo: 1000,
    Florero: costosFlorero || 19990,
    Canasta: item.tipo === 'Florero' ? 0 : costosCanasta || 19990,
    Caja: 18990,
  };

  const handleChangeRosas = (e) => {
    let cantidad = e.target.value;

    // Verificar si la cantidad ingresada es mayor a 50
    if (parseInt(cantidad) > 50) {

      Swal.fire({
        icon: 'error',
        title: 'No puedes ingresar una cantidad mayor a 50 rosas.',
        toast: true,             // Esto hace que la alerta se muestre como un toast
        position: 'center',     // Posición en la esquina superior derecha
        showConfirmButton: false, // No mostrar botón de confirmación
        timer: 3000,             // Duración de la alerta (en milisegundos)
        timerProgressBar: true,   // Muestra una barra de progreso mientras la alerta se desvanece
        background: '#f3f3f3',   // Color de fondo
        iconColor: '#a30000',     // Color del icono
        customClass: {
          title: 'my-title-class',
        }
      });
      // Establecer la cantidad a 50
      setCantidadRosas("50");
    } else {
      setCantidadRosas(cantidad);
      actualizarPrecioTotal(cantidad);
    }
  };

  const handleTipoProducto = (e) => {
    const nombre = e.target.value;
    setTipoProductoSeleccionado(nombre);
    // Usamos directamente el valor 'nombre' para actualizar el precio
    actualizarPrecioTotal(cantidadRosas, nombre);
  };

  const actualizarPrecioTotal = (cantidad, tipoSeleccionado) => {
    const precioTipoSeleccionado = preciosProductos[tipoSeleccionado] || 0;

    if (tipoSeleccionado === item.tipo || !tipoSeleccionado) {
      setPrecioTotal(cantidad * rose_unit);
    } else {
      setPrecioTotal(cantidad * rose_unit + precioTipoSeleccionado);
    }
  };

  const handleChangeRadio = (event) => {
    const index = parseInt(event.target.value);
    setSelectedOption(index);
    setTipoProductoSeleccionado(prodOptions[index].size);
    actualizarPrecioTotal(cantidadRosas, prodOptions[index].size);
  };

  useEffect(() => {
    function generateUniqueId() {
      // Esta función genera un número aleatorio entre 0 y 99999 (5 dígitos máximo)
      const randomNum = Math.floor(Math.random() * 100000);

      // Convertimos el número a una cadena y lo rellenamos con ceros a la izquierda si es necesario
      const paddedNum = String(randomNum).padStart(5, '0');

      // Escogemos dos letras al azar
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
      const randomChar1 = chars.charAt(Math.floor(Math.random() * chars.length));
      const randomChar2 = chars.charAt(Math.floor(Math.random() * chars.length));

      // Combinamos las letras y números para obtener un ID único
      return randomChar1 + randomChar2 + paddedNum;
    }
    const uniqueId = generateUniqueId();
    setOptionID(uniqueId)
  }, [cantidadRosas])

  useEffect(() => {
    if (item.categoria?.[0].includes("Especiales")) {
      setShowManualAdd(false);
    } else if (item.categoria?.[0].includes("Rosas")) {
      setShowManualAdd(true);
    } else {
      setShowManualAdd(false);
    }
  }, [item.categoria]);


  const ShowSelectedOption = ({ selectedOption, options }) => {
    // Calculamos el precio en dólares aquí
    const dolarPrice = (options[selectedOption || 0].precio / dolar).toFixed(2);

    if (selectedOption === null) {
      return (
        <div className='div-img-principal'>
          <h3 className='title-vista-opt'>Estas viendo la opción: 1 </h3>
          <p className='prod-details-size-principal'>
            Tamaño: <strong className='strong-prod-details' >{options[0].size}</strong>
          </p>
          <img className={isActive ? `${estilosDetail.productDetailImgActive}` : `${estilosDetail.imgDetail}`}
            onClick={handleClick} src={item.opciones[0].img || item.img} alt="" />
          <p className='prod-details-precio-principal'>
            Precio:
            <strong className='strong-prod-price-details'>
              {
                priceDolar ? `USD$${dolarPrice}` : `$ ${options[0].precio.toLocaleString('es-AR')}`
              }
            </strong>
          </p>

          <ItemCount

            idProd={prodId}
            optionSize={options[0].size}
            optionPrecio={options[0].precio}
            optionImg={options[0].img}
            item={item}
            quantity={quantity}
            stock={5}
            detail={true}
          />
        </div>
      )
    }

    const selectedOptionData = options[selectedOption];
    if (!selectedOptionData) {
      return null; // La opción seleccionada no se encontró
    }

    return (
      <div className='div-img-principal'>


        <h3 className='title-vista-opt'>Estas viendo la opción: {selectedOption + 1}  </h3>
        <p className='prod-details-size-principal'>
          Tamaño: <strong className='strong-prod-details' >{options[selectedOption].size}</strong>
        </p>

        <img src={selectedOptionData.img} className={estilosDetail.imgDetail} alt={`Opción ${selectedOption}`} />

        <p className='prod-details-precio-principal'>
          Precio:
          <strong className='strong-prod-price-details'>
            {
              priceDolar ? `USD$${dolarPrice}` : `$ ${options[selectedOption].precio.toLocaleString('es-AR')}`
            }
          </strong>
        </p>

        <ItemCount
          idProd={prodId}
          optionSize={options[selectedOption].size}
          optionPrecio={options[selectedOption].precio}
          optionImg={options[selectedOption].img}
          item={item}
          quantity={quantity}
          stock={5}
          detail={true}
        />
      </div>
    );
  };
// FIN DE CAMBIO DE PRODUCTO



//CAMBIAR COLOR DE PRODUCTO (ENTRE ROJO , ROSA, AMARILLO, BLANCO, MIXTO), PREDETERMINADO ROJO, SE PUEDE CAMBIAR, MISMO PRECIO. SOLO MIXTO CAMBIAR EL PRECIO, AUMENTAR $1500, Y GENERAR DESPLEGABLE CON MATERIAL UI PARA ELEGIR CANTIDAD DE CADA COLOR DEL MIXTO

  const [colorProducto, setColorProducto] = useState("Rojo");

  const handleChangeColor = (e) => {
    setColorProducto(e.target.value);
  };
  



  return (
    <>
      {
        selectedOption ?
          <Helmet>
            <title>{`${item.tipo} ${item.opciones[selectedOption].size}`} - Envio Flores - Venta y envio de flores
              a domicilio en el día a Gran Buenos Aires y Capital Federal</title>

            <meta name="description" content="Envio de flores, rosas, ramos, bombones, regalos 
                    a domicilio en Argentina. Venta online y telefónica. Pagos en efectivo y 
                    con tarjetas de crédito. Entrega inmediata. Delivery en el día en Buenos Aires. Envioflores.com" />
          </Helmet>
          :
          <Helmet>
            <title>{item.nombre} - Envio Flores - Venta y envio de flores
              a domicilio en el día a Gran Buenos Aires y Capital Federal</title>
            <meta name="description" content="Envio de flores, rosas, ramos, bombones, regalos 
                    a domicilio en Argentina. Venta online y telefónica. Pagos en efectivo y 
                    con tarjetas de crédito. Entrega inmediata. Delivery en el día en Buenos Aires. Envioflores.com" />
          </Helmet>
      }
      <div className={estilosDetail.divBack}>
        <CustomButton variant='outlined' onClick={() => navigate(-1)}>Volver atrás</CustomButton>
      </div>

      <Categorization name={item.nombre} />

      <div className={estilosDetail.divDetail}>


        {/* Titulo del producto */}
        <div className={estilosDetail.prodName}>

          <h4 className={estilosDetail.name}>{item.nombre}</h4>


          {
            item.vendidos > 10 &&
            <span className={estilosDetail.comentVendidos}>Este producto fue vendido: {item.vendidos} veces</span>

          }

        </div>

        {/* producto */}
        <div className={estilosDetail.productDetail}>

          {/* Componente opcion seleccionada */}
          <ShowSelectedOption selectedOption={selectedOption} options={item.opciones} />

          <div className={estilosDetail.divDatosProd}>
            {/* Opciones */}
            <FormControl sx={{ marginTop: isSmallScreen && '25px' }} component="fieldset">
              {/* <FormLabel sx={{ fontFamily: '"Jost", sans-serif', color:'darkred !important'}} component="legend">Opciones</FormLabel> */}
              <RadioGroup
                row
                color="secondary"
                aria-label="opciones"
                name="opciones"
                value={selectedOption !== null ? selectedOption.toString() : ''}
                onChange={handleChangeRadio}
                sx={{ justifyContent: 'center' }}
              >
                {prodOptions.map((option, index) => (
                  <FormControlLabel
                    key={index}
                    sx={{ margin: isSmallScreen ? '5px' : '10px' }}
                    value={index.toString()}
                    control={<Radio sx={{
                      fontSize: isSmallScreen ? '10px' : '12px',
                      color: grey[700],
                      '&.Mui-checked': {
                        color: pink[600],
                        fontSize: 38,
                      },
                    }} />}
                    label={
                      <div style={{ textAlign: '-webkit-center' }}>
                        <Avatar sx={{ width: isSmallScreen ? 45 : 75, height: isSmallScreen ? 45 : 75 }} alt={`Opción ${index + 1}`} src={option.img} />
                        <div className={estilosDetail.divDataOption} >

                          <h4>{option.size} </h4>
                          <span style={{ color: 'darkred' }}>Precio: ${option.precio}</span>

                        </div>
                      </div>
                    }
                  />
                ))}
              </RadioGroup>
            </FormControl>


            {/* Cambio de color */}
                {/* SOLO HABILITAR ESTA OPCION PARA LOS PRODUCTOS QUE PERTENEZCAN A LA CATEGORIA "ROSAS" */}

            {item.categoria?.includes("Rosas") && (  
            <div className={estilosDetail.divColorProd}>
              <h4 className={estilosDetail.titleColor}>Cambiar color del producto:</h4>
              <FormControl component="fieldset">
                <RadioGroup row aria-label="color" name="color" value={colorProducto} onChange={handleChangeColor}>
                  <FormControlLabel value="Rojo" control={<Radio />} label="Rojo" />
                  <FormControlLabel value="Rosa" control={<Radio />} label="Rosa" />
                  <FormControlLabel value="Amarillo" control={<Radio />} label="Amarillo" />
                  <FormControlLabel value="Blanco" control={<Radio />} label="Blanco" />
                  <FormControlLabel value="Mixto" control={<Radio />} label="Mixto" />
                </RadioGroup>
              </FormControl>
            </div>
            )}

                
                



            {/* Datos del producto */}
            <div className={estilosDetail.dataProd}>
              <p className={estilosDetail.descrProd}> {item.descr}</p>
              <h4 className={estilosDetail.categories}>Categorías a las que pertenece:</h4>
              <div className={estilosDetail.ulCategory}>
                {item.categoria?.map((category, idx) => (
                  <div key={idx} className={estilosDetail.divCategory}>
                    {category}
                  </div>
                ))}
              </div>

            </div>

          </div>




          <div>
            {showManualAdd && (
              <div className='div-options-manual-prods' key={optionID}>
                <h3 className='add-manual-rose'>Agregue manualmente la cantidad de rosas que desea enviar</h3>
                <hr />
                <label className='manual-add-prod' >
                  Cantidad de rosas:
                  <input
                    type="number"
                    value={cantidadRosas}
                    onChange={handleChangeRosas}
                    pattern="\d*"   // Aceptar solo números
                    maxLength="2"   // Limitar a 2 dígitos
                    min="1"
                    max="50"
                  />
                </label>

                <div className='div-manual-add-prod'>
                  <Typography variant='overline' sx={{ flex: '1', color: 'white', marginRight: '10px' }}>
                    Selecciona el Estilo del producto:

                  </Typography>
                  {Object.entries(preciosProductos).map(([tipo, precio]) => {
                    // Solo mostramos la opción si está permitida para el tipo de producto actual
                    if (opcionesDisponiblesPorTipo[item.tipo]?.includes(tipo) || tipo === item.tipo) {
                      if (tipo === item.tipo) {
                        return (
                          <label key={tipo} className={tipoProductoSeleccionado === tipo ? 'same-type-prod' : 'original-type-prod'}>
                            <input
                              type="radio"
                              value={tipo}
                              checked={tipoProductoSeleccionado === tipo}
                              onChange={handleTipoProducto}
                            />
                            Este producto es de estilo: <strong className='manual-add-prod'>{tipo}</strong>
                          </label>
                        );
                      }
                      return (
                        <label key={tipo} className={tipoProductoSeleccionado === tipo ? 'other-type-prod' : 'original-type-prod'}>
                          <input
                            type="radio"
                            value={tipo}
                            checked={tipoProductoSeleccionado === tipo}
                            onChange={handleTipoProducto}
                          />
                          Cambiar a: <strong className='manual-add-prod'>{tipo} (${precio})</strong>

                        </label>
                      );
                    }
                    return null; // Devolvemos null para no renderizar nada si la condición no se cumple
                  })}
                </div>
              </div>
            )}
          </div>

          {cantidadRosas && (
            <div className='div-options-prods'>
              <div style={{ marginBottom: '20px' }}>

                <div className='div-prod-details'>
                  <p className='prod-details'>
                    Estilo: <strong className='strong-prod-details' >{tipoProductoSeleccionado}</strong>
                  </p>
                  {priceDolar ? <p className='prod-details'>Precio en USD$: <strong className='strong-prod-details' >US ${precioTotalEnUsd}</strong>  </p>
                    :
                    <p className='prod-details'>Precio: <strong className='strong-prod-details' >$ {precioTotal}</strong> </p>
                  }

                </div>

                <ItemCount
                  idProd={prodId}  // Asegúrate de tener un ID para cada tipo de producto
                  optionSize={cantidadRosas + ' Rosas'}
                  optionPrecio={precioTotal}
                  optionId={optionID}
                  item={item}
                  nameOptionalSize={tipoProductoSeleccionado + ' de ' + cantidadRosas + ' Rosas'}
                  quantity={quantity}
                />

              </div>
            </div>
          )}
        </div>


        <br />

      </div>

      <div className='div-adic-list-container'>

        <AdicionalListContainer />
      </div>
    </>
  );
};

export default ItemDetail;