import React from 'react';
import { FiPhoneCall } from '@react-icons/all-files/fi/FiPhoneCall';
import { HiOutlineMail } from '@react-icons/all-files/hi/HiOutlineMail';
import { ImFacebook2 } from '@react-icons/all-files/im/ImFacebook2';
import { SiInstagram } from '@react-icons/all-files/si/SiInstagram';
import './footer.css'
import { useTheme } from '../../context/ThemeSwitchContext';

const Footer = () => {

    const { isDarkMode } = useTheme();
    const className = isDarkMode ? 'dark-mode' : '';

    return (
        <>
            <footer className={`foot ${className}`}>

                <div className={`linkFot ${className}`}>
                    <h4> Envio Flores a domicilio en el dia a todo CABA(Capital federal), y Gran Buenos Aires.  </h4>
                    <p className={`textoWebAflorar ${className}`}> Floreria en linea, venta y envio de flores en Ramos / Floreros / Canastas /
                        Desayunos y Meriendas / Chocolates / Peluches / Vinos / Bebidas y más productos
                    </p>
                </div>

                
                    <div className={`mediosDePago ${className}`}>
                        <h3>Medios De Pago</h3>
                        <p> Usted podra seleccional al finalizar la compra si comprar mediante Mercado Pago o Paypal
                            y luego usar la tarjeta de Credito / Debito que desee </p>
                        <img src={'../assets/visa@2x.png'} className="tarjetaFoot" alt="" />
                        <img src={"../assets/cabal@2x.png"} className="tarjetaFoot" alt="" />
                        <img src={"../assets/mastercard@2x.png"} className="tarjetaFoot" alt="" />
                        <img src={"../assets/mercadopago@2x.png"} className="tarjetaFoot" alt="" />
                        <img src={"../assets/pagofacil@2x.png"} className="tarjetaFoot" alt="" />
                        <img src={"../assets/paypal@2x.png"} className="tarjetaFoot" alt="" />
                        <img src={"../assets/visa@2x.png"} className="tarjetaFoot" alt="" />
                        <img src={"../assets/banelco@2x.png"} className="tarjetaFoot" alt="" />
                    </div>
            

                <div className={`contacto ${className}`}>
                    <h3>Contactanos</h3>
                    <a href="tel:+54 9 11 4788 9185" className={`contactoTel ${className}`}><FiPhoneCall /> +54 9 11 4788 9185</a>

                    <a href="mailto:ventas@aflorar.com.ar" className={`contactoMail ${className}`}><HiOutlineMail /> ventas@aflorar.com.ar </a>
                    <a href="mailto:consultas@regalosflores.com.ar" className={`contactoMail ${className}`}><HiOutlineMail /> consultas@regalosflores.com.ar </a>

                </div>

                <div className={`socialBarFooter ${className}`}>
                    <h3> Redes Sociales</h3>
                    <a href="http://facebook.com/flores.aflorar" className={`fbFoot ${className}`}><ImFacebook2 /> Facebook</a>
                    <a href="http://instagram.com/aflorar.arg" className={`instFoot ${className}`}><SiInstagram /> Instagram</a>
                </div>

            </footer>
            <div className={`legals ${className}`}>
                <p>© Envio Flores 2023. Todos los derechos reservados.</p>
                <p>E-commerce creado por © NicolasCerecedo. Contacto: <a className={`mail ${className}`}
                    href="mailto:nicocerecedo.developer@gmail.com" >nicocerecedo.developer@gmail.com</a></p>

            </div>
        </>
    );
};

export default Footer;