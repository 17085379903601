import React, { useContext, useEffect, useState } from 'react';
import { Stepper, Step, StepLabel, Alert, StepConnector, stepConnectorClasses, useMediaQuery } from '@mui/material';
import './checkout.css'
import styled from '@emotion/styled';
import { blue } from '@mui/material/colors';
import { CookieContext } from '../../context/CookieContext';
import { useTheme } from '../../context/ThemeSwitchContext';

const steps = ['Seleccione un Producto', 'Vea las opciones del Producto. Ademas puede agregar productos Adicionales', ' Verifique los productos de su Carrito', 'Agregue la Información de Envío', 'Seleccione un metodo de pago y Finalice la compra.'];
const stepsDone = ['Listo', 'Hecho', 'Carrito verificado', 'Informacion de envio agregada', 'Seleccione un metodo de pago y Finalice la compra.'];


const CheckoutStepper = ({ activeStep, cartEmpty, cartFull, cookiesAcepted }) => {

  const isSmallScreen = useMediaQuery('(max-width:850px)');
  const [customLabels, setCustomLabels] = useState(Array.from({ length: steps.length }, (_, index) => steps[index]));
    //Cookie
    const { acceptedCookies } = useContext(CookieContext);
  const {isDarkMode} = useTheme()

  useEffect(() => {
    // Al pasar a un nuevo paso, actualiza los labels personalizados de los pasos anteriores y el actual
    if (activeStep > 0) {
      const updatedLabels = [...customLabels];
      for (let i = 0; i < activeStep; i++) {
        updatedLabels[i] = stepsDone[i];
      }
      setCustomLabels(updatedLabels);
    }
  }, [activeStep]);


  
  const CustomStepLabel = styled(StepLabel)({
    // color: '#fff',

    '& .MuiStepLabel-label':{   
    fontSize: isSmallScreen ? '0.54rem' : '1rem',
      fontWeight: isSmallScreen && '600',
      letterSpacing: isSmallScreen && '0.08071em',

    },
    '& .MuiStepLabel-active': {
      color: isDarkMode ? 'white': '#a00303',
    },

    '& .MuiStepLabel-label.Mui-completed': {
      color: isDarkMode ? 'darkgreen':'silver',
      
    },
    '& .MuiStepIcon-text':{
      fontSize: isSmallScreen && '0.84rem' ,

    },
    '& .MuiSvgIcon-root.MuiStepIcon-root.Mui-active': {
      color: 'transparent',
      backgroundImage:
            'linear-gradient( 180deg,red 0%,#dd2323 50%,#a80000 100%)',
      padding: isSmallScreen && '1.25px' ,
      borderRadius: '50%',
    },
  });


const CustomStep = styled(Stepper, Step)(({ theme }) => ({

  color:' grey',
 ' & .MuiStepLabel-label.Mui-active': {
    color: activeStep >= 3 ? 'grey' : (isDarkMode ? 'white' : '#a00303')
 },
 ' & .MuiStepIcon-root.Mui-completed': {
    color: '#035b0e',
    backgroundImage:'#035b0e',
  },
  
 ' & .MuiStepLabel-labelContainer': {
    width: '100%',
    color: isDarkMode ? 'dimgrey':'silver',

  },
}));


  if (!acceptedCookies) {
    return (
      <Alert severity="error" sx={{ margin:'20px 5px'}}>
        Debes aceptar las cookies para continuar con la compra.
      </Alert>
    );
  }

  if (cartEmpty) {
    return (
      <Alert severity="error" sx={{ margin:'20px 5px'}}>
        El carrito está vacío. Agrega productos antes de proceder.
      </Alert>
    );
  }

  if (activeStep >= 1 ) {
    return (
      <CustomStep sx={{ marginBottom: 2, marginTop: '45px' }} activeStep={activeStep} alternativeLabel>
        {customLabels.map((label, index) => (
          <Step key={index}>
            <CustomStepLabel className='StepLabel'>
              {label}
            </CustomStepLabel>
          </Step>
        ))}
      </CustomStep>
    );
  }

  return (
    <CustomStep sx={{ marginBottom: 2, marginTop:'45px',   }} activeStep={activeStep} alternativeLabel  >

      {steps.map((label, index) => (

        <Step sx={{}} key={index}>

           <CustomStepLabel className='StepLabel' >
            {label}
          </CustomStepLabel>
        </Step>
      ))}
    </CustomStep>
  );
};

export default CheckoutStepper;
