/* eslint-disable array-callback-return */
import React, { useContext, useEffect, useState } from "react";
import { SearchContext } from "../../context/SearchContext";
import { useTheme } from "../../context/ThemeSwitchContext";


const Searcher = ({ items }) => {

  const { changeList, navBarSearcher , setNavBarSearcher,  setClearInputNavBar, clearInputMain, setClearInputMain, clearInputNavBar} = useContext(SearchContext)

  let [itemEncontrado, setItemEncontrado] = useState([])
  const [busqueda, setBusqueda] = useState('');
  const {isDarkMode} = useTheme();
  const className = isDarkMode ? 'dark-mode' : '';
  const handleChange = (evento) => {
    
    evento.preventDefault();
    setNavBarSearcher(false)
    setBusqueda(evento.target.value);
    filtrado(evento.target.value);
  };

  useEffect(() => {
    if (clearInputMain && busqueda && !navBarSearcher) {
      setClearInputNavBar(true);
      setClearInputMain(false);
    } else if (clearInputMain && busqueda) {
      setBusqueda('');
    }
  }, [clearInputMain, busqueda, navBarSearcher, setClearInputNavBar, setClearInputMain]);


  const filtrado = (prodBuscado) => {
  
    if(!navBarSearcher){
      const restultadoBusqueda = items.filter((prod) => {
        if (prod.nombre.toString().toLowerCase().includes(prodBuscado.toLowerCase())) {
          return prod;
        }
      })
      setItemEncontrado(restultadoBusqueda)
    }
  };

  const handleSubmit = (evento) => {
    evento.preventDefault();
    evento.stopPropagation();
  }
  
  useEffect(() => {
    changeList(itemEncontrado)
  }, [ itemEncontrado]);

  return (
    <div className="div-buscador-chg-usd">
      <div className="buscador-div">
        <h5 className={`buscador-title ${className}`}>Escriba el producto que desea</h5>
        <form className="form-buscador" onSubmit={handleSubmit}   >
          <input
            type="text"
            className={busqueda ? 'input-buscador-full' : 'input-buscador-empty'}
            value={busqueda}
            placeholder="Buscar Producto..."
            onChange={handleChange}
          />
          {/* <button onClick={handleResetSearch} className="buscador-btn">Refrescar</button> */}

        </form>
      </div>


    </div>
  )
}

export default Searcher