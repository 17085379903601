import React, { useContext, useEffect, useRef, useState } from "react";
import { useParams } from 'react-router-dom'
import ItemList from "../itemList/ItemList";
import { FadeLoader } from "react-spinners";
import './itemListContainer.css'
import { SearchContext } from "../../context/SearchContext";
import Searcher from "../Searcher/Searcher";
import axios from "axios";
import PaginationComponent from "../Pagination/Pagination";
import { Helmet } from 'react-helmet';
import nacl from 'tweetnacl';
import * as naclUtil from 'tweetnacl-util';
import { Accordion, AccordionDetails, AccordionSummary, Checkbox, Paper, Typography, createTheme, useMediaQuery } from '@mui/material';
import { grey } from '@mui/material/colors';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { collection, getDocs, query } from "firebase/firestore";
import { baseDeDatos } from "../../admin/FireBaseConfig";
import SearcherNavBar from "../SearcherNavBar/SearcherNavBar";
import { useTheme } from "../../context/ThemeSwitchContext";


const ItemListContainer = ({ activeStep, stepLabels, onNextStep }) => {

    const isSmallScreen = useMediaQuery('(max-width:650px)');
    const { isDarkMode } = useTheme();
    const className = isDarkMode ? 'dark-mode' : '';

    const theme = createTheme();

    theme.typography.h2 = {
        color: theme.palette.getContrastText(grey[900]),
        padding: 20,
        fontFamily: 'Carattere, sans-serif',
        fontSize: '1.2rem',
        '@media (min-width:600px)': {
            fontSize: '1.5rem',
        },
        [theme.breakpoints.up('md')]: {
            fontSize: '2.4rem',
        },
    };

    const { categoryName, ocasionName } = useParams()
    const { prodEncontrado } = useContext(SearchContext);
    const [items, setItems] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [sortOrder, setSortOrder] = useState(''); // Valor predeterminado.
    const [totalProcessedItems, setTotalProcessedItems] = useState(0);
    const [totalItems, setTotalItems] = useState(0);
    const PAGE_SIZE = 7;
    const [currentPage, setCurrentPage] = useState(1); // Página actual

    const lastCategoryName = useRef(categoryName); // Referencia al último valor de categoryName
    const lastOcasionName = useRef(ocasionName); // Referencia al último valor de categoryName

    const clearLocalStorage = () => {

        return new Promise((resolve) => {
            localStorage.removeItem('p');
            resolve();
        });
    };


    useEffect(() => {


        async function fetchDataIfNeeded() {
            if (!localStorage.getItem('p')) {
                try {
                    const productsCollection = query(collection(baseDeDatos, 'productos'));
                    const productSnapshot = await getDocs(productsCollection);
                    const productList = productSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));
                    // Encriptar antes de almacenar en localStorage
                  
                    const key = nacl.randomBytes(nacl.secretbox.keyLength);
                    const nonce = nacl.randomBytes(nacl.secretbox.nonceLength);
                    const encryptedData = nacl.secretbox(
                        naclUtil.decodeUTF8(JSON.stringify(productList)),
                        nonce,
                        key
                    );

                    localStorage.setItem('p', JSON.stringify({
                        nonce: naclUtil.encodeBase64(nonce),
                        key: naclUtil.encodeBase64(key),
                        data: naclUtil.encodeBase64(encryptedData),
                    }));

                    return productList;
                } catch (error) {
                    console.error("No se pudo obtener la base de datos:", error.response || error);
                    await clearLocalStorage(); // No olvides esperar a que se limpie el almacenamiento local
                    fetchDataIfNeeded(); // Vuelve a realizar la petición después de limpiar el localStorage
                    return [];
                }
            } else {
                try {

                    // // Encriptar antes de almacenar en localStorage
                    const productsCollection = query(collection(baseDeDatos, 'productos'));
                    const productSnapshot = await getDocs(productsCollection);
                    const productList = productSnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id }));

                    const keyFirebase = nacl.randomBytes(nacl.secretbox.keyLength);
                    const nonceFirebase = nacl.randomBytes(nacl.secretbox.nonceLength);
                    const encryptedDataFirebase = nacl.secretbox(
                        naclUtil.decodeUTF8(JSON.stringify(productList)),
                        nonceFirebase,
                        keyFirebase
                    );

                    localStorage.setItem('p', JSON.stringify({
                        nonce: naclUtil.encodeBase64(nonceFirebase),
                        key: naclUtil.encodeBase64(keyFirebase),
                        data: naclUtil.encodeBase64(encryptedDataFirebase),
                    }));


                    const storedEncryptedData = localStorage.getItem('p');
                    const parsedStoredData = JSON.parse(storedEncryptedData);

                    if (!parsedStoredData.nonce || !parsedStoredData.key || !parsedStoredData.data) {
                        console.log('Datos almacenados incompletos');
                        await clearLocalStorage(); // No olvides esperar a que se limpie el almacenamiento local
                        fetchDataIfNeeded(); // Vuelve a realizar la petición después de limpiar el localStorage
                    }

                    const nonce = naclUtil.decodeBase64(parsedStoredData.nonce);
                    const key = naclUtil.decodeBase64(parsedStoredData.key);

                    // Decodificar la cadena en base64 antes de desencriptar
                    const encryptedData = naclUtil.decodeBase64(parsedStoredData.data);

                    const decryptedData = nacl.secretbox.open(
                        encryptedData,
                        nonce,
                        key
                    );

                    if (decryptedData) {
                        const textDecoder = new TextDecoder('utf-8');
                        const decryptedDataString = textDecoder.decode(decryptedData);

                        const parsedData = JSON.parse(decryptedDataString);

                        return parsedData;
                    } else {
                        console.error('Error al desencriptar los datos.');
                        await clearLocalStorage(); // No olvides esperar a que se limpie el almacenamiento local
                        fetchDataIfNeeded(); // Vuelve a realizar la petición después de limpiar el localStorage
                    }
                } catch (error) {
                    console.error("Error al parsear datos almacenados:", error);
                    await clearLocalStorage(); // No olvides esperar a que se limpie el almacenamiento local
                    fetchDataIfNeeded(); // Vuelve a realizar la petición después de limpiar el localStorage
                }
            }
        }

        fetchDataIfNeeded().then(data => {
            let processedItems = data;

            if (categoryName) {
                processedItems = processedItems.filter((prod) => prod.categoria.some((category) => category.includes(categoryName)));

            }


            if (ocasionName) {
                // Filtra solo los productos que tienen el campo "ocasiones"
                const productosConOcasion = processedItems.filter(prod => prod.ocasiones && prod.ocasiones.length > 0);
                if (productosConOcasion.length > 0) {
                    // Si hay productos con el campo "ocasiones", filtra por "ocasionName"
                    processedItems = productosConOcasion.filter((prod) => prod.ocasiones.some((ocasion) => ocasion.includes(ocasionName)));
                } else {
                    // Si no hay productos con el campo "ocasiones", establece processedItems como vacío
                    processedItems = [];
                }
            }

            switch (sortOrder) {
                case 'recientes':
                    processedItems.sort((a, b) => {
                        if (a.createdAt && b.createdAt) {
                            return b.createdAt - a.createdAt;
                        }
                        // Si uno de los productos no tiene createdAt, colócalo al final
                        if (!a.createdAt) return 1;
                        if (!b.createdAt) return -1;
                        // En caso de que ambos productos no tengan createdAt, no cambia el orden entre ellos
                        return 0;
                    });
                    break;
                case 'barato':
                    processedItems.sort((a, b) => (a.opciones[0].precio) - (b.opciones[0].precio));
                    break;
                case 'caro':
                    processedItems.sort((a, b) => (b.opciones[0].precio) - (a.opciones[0].precio));
                    break;
                case 'vendidos':
                    processedItems.sort((a, b) => b.vendidos - a.vendidos);
                    break;
                case 'alfabeto1':
                    processedItems.sort((a, b) => a.nombre.localeCompare(b.nombre));
                    break;
                case 'alfabeto2':
                    processedItems.sort((a, b) => b.nombre.localeCompare(a.nombre));
                    break;
                default:
                    break;
            }

            if (categoryName) {
                if (categoryName !== lastCategoryName.current) {
                    setCurrentPage(1);
                    lastCategoryName.current = categoryName;
                }

                setTotalItems(processedItems.length);
                // Calcular el rango de productos a mostrar según la página actual y el tamaño de la página
                const startIdx = (currentPage - 1) * PAGE_SIZE;
                const endIdx = currentPage * PAGE_SIZE;

                processedItems = processedItems.slice(startIdx, endIdx);

                setItems(processedItems);
                setIsLoading(false);
            } else {
                setTotalProcessedItems(0);
                setIsLoading(false);
            }

            if (ocasionName) {
                if (ocasionName !== lastOcasionName.current) {
                    setCurrentPage(1);
                    lastOcasionName.current = ocasionName;
                }

                setTotalProcessedItems(processedItems.length);

                // Calcular el rango de productos a mostrar según la página actual y el tamaño de la página
                const startIdx = (currentPage - 1) * PAGE_SIZE;
                const endIdx = currentPage * PAGE_SIZE;

                processedItems = processedItems.slice(startIdx, endIdx);

                setItems(processedItems);
                setIsLoading(false);
            } else {
                setTotalProcessedItems(0);
                setIsLoading(false);
            }

            setItems(processedItems);
            setIsLoading(false);

        },
        )
        // fetchDataIfNeeded();

    }, [categoryName, currentPage, ocasionName, sortOrder]);


    useEffect(() => {
        if (localStorage.getItem('productos')) {
            localStorage.removeItem('productos');
        }
    }, []);

    return (
        <div className="products" id="productos">

            {
                categoryName &&
                <Helmet>
                    <title>{categoryName} Envio Flores - Venta y envio de {categoryName} a domicilio en el día
                        a Gran Buenos Aires y Capital Federal</title>
                    <meta name="robots" content="index, follow" />
                    <meta name="description" content="Envio de flores, rosas, ramos, bombones, regalos
                    a domicilio en Argentina. Venta online y telefónica. Pagos en efectivo y
                    con tarjetas de crédito. Entrega inmediata. Delivery en el día en Buenos Aires. Envioflores.com" />
                </Helmet>
            }


            {isLoading ? (
                <>
                    <h1 className="loadProd">Cargando Productos...</h1>
                    <FadeLoader className="fadeLoader" color="rgb(255, 255, 255)" />
                </>
            ) : (
                <>
                    {
                        isSmallScreen ?
                        <div style={{ display: 'flex',   background: isDarkMode ? '#420000' : 'transparent',
                        justifyContent: 'center', flexDirection: 'wrap', flexWrap: 'wrap' , margin:'0', flexDirection:'column'}} >

                                <Searcher items={items} />


                                <Accordion sx={{ marginTop: '25px', background: isDarkMode ? '#420000' : '#670000' }}>
                                    <AccordionSummary
                                        expandIcon={<ExpandMoreIcon />}
                                        aria-controls="panel1a-content"
                                        id="panel1a-header"
                                        sx={{ background: isDarkMode ? '#420000' : '#670000', color: 'white', justifyContent:'center' }}
                                    >
                                        <Typography sx={{ fontWeight: '700', textAlign:'center'}}>Ordenar Productos</Typography>
                                    </AccordionSummary>
                                    <AccordionDetails sx={{ background: 'linear-gradient(to top, white, silver)', padding: '8px 1px 16px', border: '1px solid darkred' }}>
                                        <div className="div-filtros">


                                            <label>
                                                <Checkbox
                                                    color="success"
                                                    type="checkbox"
                                                    value="recientes"
                                                    checked={sortOrder === "recientes"}
                                                    onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                /> Recién añadidos
                                            </label>

                                            <div>
                                                <label>
                                                    <Checkbox
                                                        color="success"
                                                        type="checkbox"
                                                        value="barato"
                                                        checked={sortOrder === "barato"}
                                                        onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                    /> Menor precio
                                                </label>
                                                <label>
                                                    <Checkbox
                                                        color="success"
                                                        type="checkbox"
                                                        value="caro"
                                                        checked={sortOrder === "caro"}
                                                        onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                    /> Mayor precio
                                                </label>

                                            </div>

                                            <label>
                                                <Checkbox
                                                    color="success"
                                                    type="checkbox"
                                                    value="vendidos"
                                                    checked={sortOrder === "vendidos"}
                                                    onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                /> Más vendidos
                                            </label>

                                            <div>

                                                <label>
                                                    <Checkbox className=""
                                                        color="success"
                                                        type="checkbox"
                                                        value="alfabeto1"
                                                        checked={sortOrder === "alfabeto1"}
                                                        onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                    /> Ordenar de A / Z
                                                </label>

                                                <label>
                                                    <Checkbox className=""
                                                        color="success"
                                                        type="checkbox"
                                                        value="alfabeto2"
                                                        checked={sortOrder === "alfabeto2"}
                                                        onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                    /> Ordenar de Z / A
                                                </label>
                                            </div>


                                        </div>
                                    </AccordionDetails>
                                </Accordion>
                            </div>
                            :
                            <div style={{ display: 'flex',   background: isDarkMode ?  '#420000' : '#670000' ,
                             justifyContent: 'center', flexDirection: 'wrap', flexWrap: 'wrap' , margin:'0 20px', flexDirection:'column'}} >

                                <div style={{ flex: '1' }}>
                                    <Searcher items={items} />
                                </div>


                                <Paper elevation={1} sx={{ display: 'flex', flex: '2', flexDirection: 'row',  margin:'0 20px',
                              background:'transparent', color:'white'   }}>
                                    <div className="div-filtros">

                                        <label>
                                            <Checkbox
                                                color="success"
                                                type="checkbox"
                                                value="recientes"
                                                checked={sortOrder === "recientes"}
                                                onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                            /> Recién añadidos
                                        </label>

                                       
                                            <label>
                                                <Checkbox
                                                    color="success"
                                                    type="checkbox"
                                                    value="barato"
                                                    checked={sortOrder === "barato"}
                                                    onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                /> Menor precio
                                            </label>
                                            <label>
                                                <Checkbox
                                                    color="success"
                                                    type="checkbox"
                                                    value="caro"
                                                    checked={sortOrder === "caro"}
                                                    onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                /> Mayor precio
                                            </label>

                                      

                                        <label>
                                            <Checkbox
                                                color="success"
                                                type="checkbox"
                                                value="vendidos"
                                                checked={sortOrder === "vendidos"}
                                                onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                            /> Más vendidos
                                        </label>

                                       
                                            <label>
                                                <Checkbox className=""
                                                    color="success"
                                                    type="checkbox"
                                                    value="alfabeto1"
                                                    checked={sortOrder === "alfabeto1"}
                                                    onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                /> Ordenar de A / Z
                                            </label>

                                            <label>
                                                <Checkbox className=""
                                                    color="success"
                                                    type="checkbox"
                                                    value="alfabeto2"
                                                    checked={sortOrder === "alfabeto2"}
                                                    onChange={e => setSortOrder(prevOrder => prevOrder === e.target.value ? 'sin_ordenacion' : e.target.value)}
                                                /> Ordenar de Z / A
                                            </label>
                                       
                                    </div>
                                </Paper>
                            </div>


                    }

                    {
                        categoryName ?
                            < >

                                <div>

                                    <h3 className={`cat-selected ${className}`}>Estás viendo la Categoría:

                                        <strong className={`cat-selected-strong ${className}`}> {categoryName} </strong> </h3>
                                </div>

                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalItems={totalItems}
                                    setCurrentPage={setCurrentPage}
                                    page_size={PAGE_SIZE}
                                />
                            </>

                            : null}

                    {
                        ocasionName ?
                            < >

                                <div>

                                    <h3 className={`cat-selected ${className}`}>Estás viendo la Categoría:

                                        <strong className={`cat-selected-strong ${className}`} > {ocasionName} </strong> </h3>
                                </div>

                                <PaginationComponent
                                    currentPage={currentPage}
                                    totalItems={totalProcessedItems}
                                    setCurrentPage={setCurrentPage}
                                    page_size={PAGE_SIZE}
                                />
                            </>

                            : null}

                    <ItemList items={items} prodEncontrado={prodEncontrado} />
                    { ocasionName || categoryName &&      <PaginationComponent
                                    currentPage={currentPage}
                                    totalItems={totalProcessedItems}
                                    setCurrentPage={setCurrentPage}
                                    page_size={PAGE_SIZE}
                                />
                    }
                </>
            )}
        </div>
    );

};

export default ItemListContainer;