import React, { createContext, useState } from 'react';

export const SearchContext = createContext();

const SearchProvider = ({ children }) => {
    const [prodEncontrado, setProdEncontrado] = useState([]);
    const [navBarSearcher, setNavBarSearcher] = useState(false);
    const [clearInputNavBar, setClearInputNavBar] = useState(false);
    const [ clearInputMain, setClearInputMain ] = useState(false)
 
    const changeList = (item) => {
        if (navBarSearcher) {
            setProdEncontrado(item);
        } else {
            setProdEncontrado(item);
        }
    };
 
    return (
        <SearchContext.Provider
            value={{
                prodEncontrado,
                changeList,
                setNavBarSearcher,
                navBarSearcher,
                setClearInputNavBar,
                clearInputNavBar,
                setClearInputMain,
                clearInputMain,

            }}
        >
            {children}
        </SearchContext.Provider>
    );
};

export default SearchProvider;
