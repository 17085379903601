import React from 'react';

function FailurePage() {
  return (
    <div className='compraFinalizada'>
      <h1>Compra Fallida</h1>
      <p>Tu compra NO ha sido procesada correctamente. Ha habido un error con Mercado Pago.</p>
    </div>
  );
}

export default FailurePage;
